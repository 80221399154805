// Bootstrap
@import 'variables';
@import "~bootstrap/scss/bootstrap";
footer {
    .logo-f {
        height: auto;
        width: 400px;
        margin-bottom: 34px;
    }

    .section-1 {
        font-size: 30px;
        font-weight: 600;
    }


    .section-2 {
        font-size: 30px;
        font-weight: 600;
        p{
            color: $danger;
        }
        a{
            color: $dark;
            text-decoration: none;
            font-weight: bold;
            &:hover{
                color:$danger;
            }
        }
    }
    @include media-breakpoint-down(md) {

        .section-1, .section-2{
            font-size: 20px;
        }


    }
}
