// Bootstrap
@import "~bootstrap/scss/bootstrap";
#header .menu .nav .nav-link .dropdown-content {
    padding: 10px;
    margin-top: -15px;
}
#header .menu .nav .nav-link .dropdown-content a {
    font-size: 15px !important;
}

#header {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;

    .menu .nav {
        max-width: 991px;
        margin: auto;
    }

    .bg-header {
        background: url(../img/header/bg-header.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 102% 110%;
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-h {
            width: 480px;
            height: auto;
            // height: 280px;
            margin: 50px 0;
        }
    }
    @include media-breakpoint-down(md) {
        .bg-header {
            background: url(../img/header/bg-header.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 102% 110%;
            width: 100%;
            max-width: 100%;
            height: 220px;
            display: flex;
            align-items: center;
            justify-content: center;

            .logo-h {
                height: 260px;
                margin: 40px 0;
            }
        }
    }
    .menu {
        position: absolute;
        width: 100%;
        bottom: -25%;
        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .nav-link {
                transition: all 1.2s ease;
                position: relative;
            }

            .nav-link .dropdown-content {
                position: absolute;
                display: none;
                a {
                    font-size: 17px;
                    font-weight: 500;
                    color: #242323;
                    text-decoration: none;
                    &:hover{
                        color: $primary;
                        font-weight: 700;
                    }
                }
            }
            .nav-link:hover .dropdown-content {
                display: flex;
                flex-flow: column;
                background: #ebebeb;
                color: #1a1d27;
                width: 200px;
                padding-left: 15px;
                padding-right: 15px;
            }

            .nav-link:hover,
            .nav-link:focus {
                transform: scale(1.1);
                transition: all 1.2s ease;
            }
            .nav-link .nav-item {
                height: 100px;
                width: 100px;
            }
        }
    }
    @include media-breakpoint-down(md) {
        .menu {
            bottom: 0rem;
            position: relative;
            background-color: aliceblue;
        }
    }
}
@include media-breakpoint-down(md) {
    #header {
        display: block;
    }
}
