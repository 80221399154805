// Body
$body-bg: #f8fafc;
// Typography
$font-family-sans-serif: "Segoe UI Regular", sans-serif;
$line-height-base: 1.6;
// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #9A0002;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #680c00;
$secondary: #044a4d;
$success: #059117;
$info: #079fb9;
$warning: #9b8c04;
$danger: #9A0002;
$light: #F3F2F2;
$dark: #242323;

$body-color: #000000;
$font-size-base: 1.2rem;
